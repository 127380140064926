<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("Optometrist") }}</label>
      <span>/</span>
      <label active>{{ $t("Optometrists") }}</label>
    </div>

    <!-- Start Dialog Delete -->
    <v-dialog v-model="dialogDelete" :width="config.deleteModalWidth">
      <v-card>
        <v-toolbar color="third" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">
            {{ $t("AreYouSureYouWantToDelete") }}
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogDelete = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="deleteItemConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog Delete -->

    <v-toolbar flat>
      <v-toolbar-title>{{ $t(config.tableName) }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn color="info" @click="AddNew" class="mb-2" small>
        <span class="addNewLabel">{{ $t("AddNew") }}</span>

        <v-icon class="my-0 mr-2 ml-2" dense>mdi-file</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- Start Filters -->
    <div>
      <v-row>
        <v-col :md="2">
          <div>
            <label>{{ $t("User") }}</label>
            <v-autocomplete
              class="d-block my-2"
              name="admin_id"
              hide-details
              dense
              outlined
              v-model="filters.admin_id"
              item-text="name"
              item-value="id"
              clearable
              :items="adminList"
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col :md="2">
          <div>
            <label>{{ $t("Patient") }}</label>
            <v-text-field
              class="d-block my-2"
              type="text"
              name="name_ar"
              hide-details
              dense
              outlined
              v-model="filters.name"
            ></v-text-field>
          </div>
        </v-col>
        <v-col :md="2">
           <div>
            <label>{{ $t("Mobile") }}</label>
            <v-text-field
              class="d-block my-2"
              type="text"
              name="mobile"
              hide-details
              dense
              outlined
              v-model="filters.mobile"
            ></v-text-field>
          </div>
        </v-col>

        <v-col :md="2">
          <div>
            <label>{{ $t("Report") }}</label>
            <v-autocomplete
              class="d-block my-2"
              name="report"
              hide-details
              dense
              outlined
              v-model="filters.date"
              item-text="name"
              item-value="id"
              clearable
              :items="reportList"
              @change="changeFilter"
            ></v-autocomplete>
          </div>
        </v-col>
           <v-col :md="2">
          <div>
            <label>{{ $t("From") }}</label>
            <el-date-picker
              class="d-block my-2"
              style="width: 100%"
              name="from_date"
              :disabled="filters.date != 'custom'"
              v-model="filters.from"
            ></el-date-picker>
          </div>
        </v-col>

        <v-col :md="2" >
          <div>
            <label>{{ $t("To") }}</label>
            <el-date-picker
              class="d-block my-2"
              style="width: 100%"
              name="from_date"
              v-model="filters.to"
               :disabled="filters.date != 'custom'"
            ></el-date-picker>
          </div>
        </v-col>
      </v-row>
      <v-row style="margin-top:-40px;margin-bottom:10px;">
        <v-col :md="1" class="mt-6">
          <v-btn
            color="third"
            hide-details
            @click="getDataFromApi"
            style="display: block; margin-top: 10px"
            class="w-full h-btn white-clr"
            small
          >
            {{ $t("Filter") }}
          </v-btn>
        </v-col>
        <v-col :md="1" class="mt-6">
          <v-btn
            color="danger"
            hide-details
            @click="clearFilters"
            style="display: block; margin-top: 10px"
            class="w-full h-btn white-clr"
            small
          >
            {{ $t("Clear") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- End Filters -->

    <!-- Start DataTable -->
    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      :items-per-page="table.itemsPerPage"
      hide-default-footer
      class="elevation-1"
      :loading-text="$t('LoadData')"
    >
      <template v-slot:no-data>
        <v-alert class="mt-5 white-clr" color="third">
          {{ $t("NoData") }}
        </v-alert>
      </template>

      <template v-slot:item.patient_name="{ item }">
        <p
          @click="goDetails(item.patient_id)"
          class="my-0 clr-blue bold-text clickable"
        >
          {{ item.patient_name }}
        </p>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          class="my-0 mr-2 ml-2"
          dense
          @click="editData(item)"
          color="success"
          >mdi-pencil</v-icon
        >

        <v-icon class="my-0 mr-2 ml-2 detailsIcon" dense @click="goPdf(item.id)"
          >mdi-eye</v-icon
        >

        <svg
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          class="my-0 mr-2 ml-2 downloadIcon"
          @click="downloadPdf(item.id)"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H8V4h12v12zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm12 6V9c0-.55-.45-1-1-1h-2v5h2c.55 0 1-.45 1-1zm-2-3h1v3h-1V9zm4 2h1v-1h-1V9h1V8h-2v5h1zm-8 0h1c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1H9v5h1v-2zm0-2h1v1h-1V9z"
          />
        </svg>
        <v-icon
          class="my-0 mr-2 ml-2"
          dense
          @click="
            formData = Object.assign({}, item);
            dialogDelete = true;
          "
          color="danger"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <!-- End DataTable -->

    <!-- Start Pagination -->
    <div class="text-center pt-2">
      <v-pagination
        v-if="table.length"
        total-visible="5"
        v-model="table.page"
        :length="table.length"
        @input="changePage(table.page)"
      ></v-pagination>
    </div>

    <!-- End Pagination -->
  </div>
</template>

<script>
import moment from "moment";
import config from '@/config';
export default {
  name: "optometrists",
  data() {
    return {
      adminList: [],
      reportList: [
        {
          id: "day",
          name: "Day",
        },
        {
          id: "week",
          name: "Week",
        },
        {
          id: "month",
          name: "Month",
        },
        {
          id: "year",
          name: "Year",
        },
        {
          id: "custom",
          name: "Custom",
        },
      ],
      filtersItems: [
        { text: this.$t("All"), value: null },
        { text: this.$t("Active"), value: 1 },
        { text: this.$t("InActive"), value: 0 },
      ],
      config: {
        tableName: "OptometristsTable",
        crudHeader: "Optometrists",
        crudApi: "optometristReport",
        filterWidth: "20rem",
        deleteModalWidth: "30rem",
        modalFormWidth: "50rem",
      },
      table: {
        page: 1,
        length: null,
        itemsPerPage: 15,
        loading: false,
        items: [],
        itemExport: {
          "#": "id",
          "الاسم عربي": {
            field: "name.ar",
            callback: (item) => {
              if (item) return item;
            },
          },
          "الاسم إنجليزي": {
            field: "name.en",
            callback: (item) => {
              if (item) return item;
            },
          },
        },
        headers: [
          { text: "#", value: "id", width: "5rem" },
          {
            text: this.$t("RegDate"),
            value: "reg_date",
            width: "10rem",
          },
           {
            text: this.$t("User"),
            value: "admin",
            width: "8rem",
          },
          {
            text: this.$t("Patient"),
            value: "patient_name",
            width: "8rem",
          },
          {
            text: this.$t("Mobile"),
            value: "patient_mobile",
            width: "8rem",
          },
          {
            text: this.$t("Actions"),
            value: "actions",
            sortable: false,
            align: "center",
            width: "8rem",
          },
        ],
      },
      filters: {
        name: null,
        admin_id: null,
        mobile: null,
        date: null,
        from: null,
        to: null,
      },
      formData: {
        name: {
          en: "",
        },
      },
      genderItems: [
        {
          id: 1,
          name: this.$t("Male"),
        },
        {
          id: 2,
          name: this.$t("Female"),
        },
      ],
      drawer: false,
      dialogDelete: false,
    };
  },
  methods: {
    getAdminList() {
      this.$store.dispatch(`admin/listData`).then((res) => {
        this.adminList = res.data;
      });
    },
    goPdf(id) {
      window.open(`/#/reports/optometrists/report/${id}`);
    },
    downloadPdf(id) {
        window.open(`${config.hostUrl}/generate_pdf/${id}`);
    },
    goDetails(id) {
      this.$router.push({
        name: "optometristsPatientDetails",
        params: { id: id },
      });
    },
    changeFilter() {
      if (this.filters.date !== "custom") {
        this.filters.from = "";
        this.filters.to = "";
      }
    },
    editData(item) {
      this.$router.push({
        name: "optometristsAddEdit",
        params: { id: item.id },
      });
    },
    clearFilters() {
      for (let key in this.filters) {
        this.filters[key] = null;
      }
      this.getDataFromApi();
    },
    AddNew() {
      this.$router.push({ name: "optometristsAddEdit" });
    },
    deleteItemConfirm() {
      this.deleteData(
        `${this.config.crudApi}/removeData`,
        this.formData.id
      ).then(() => {
        this.getDataFromApi();
        this.dialogDelete = false;
      });
    },
    updateStatus(item) {
      item.isLoading = true;
      this.updateStatusData(`${this.config.crudApi}/updateStatus`, item.id)
        .then(() => {
          this.getDataFromApi();
        })
        .finally(() => {
          item.isLoading = false;
        });
    },
    closeModal() {
      this.formData.id = 0;
      this.dialog = false;
      this.$validator.reset();
    },
    getDataFromApi(options) {
      let sendFilters = JSON.parse(JSON.stringify(this.filters));

      if (sendFilters.from)
        sendFilters.from = moment(this.filters.from).format("YYYY-MM-DD");

      if (sendFilters.to)
        sendFilters.to = moment(this.filters.to).format("YYYY-MM-DD");

      this.table.loading = true;
      this.getFrontEndData(
        `${this.config.crudApi}/getData`,
        options,
        sendFilters
      )
        .then((res) => {
          this.table.items = res.resources;
          this.table.length = Math.ceil(
            res.pagination.total / res.pagination.per_page
          );
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    changePage(page) {
      let options = {
        page: page,
      };
      this.getDataFromApi(options);
    },
  },
  mounted() {
    this.getAdminList();
    this.getDataFromApi();
  },
};
</script>


<style>
.detailsIcon::before {
  color: brown;
}
.downloadIcon::before {
  color: rgb(0, 170, 105);
}

.downloadIcon {
  fill: rgb(238, 176, 4);
  position: relative;
  top: 7px;
  cursor: pointer;
}
.clr-blue {
  color: blue;
}
.bold-text {
  font-weight: bold;
}
</style>